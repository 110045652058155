body{
  margin: 0;
  padding: 0;
  font-family: Raleway Regular;
  font-size: 20px;
}

#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-attachment: fixed;
  background-image: url(https://storage.googleapis.com/assets-impregnarte/images/background.png);
  background-position: center 80px;
  background-repeat: no-repeat;
  background-size: cover;
}

header{
  z-index: 2;
}

main{
  z-index: 1;
  flex-grow: 1;
}

footer{
  z-index: 2;
}

/* SCROLLBAR */
@media (min-width: 768px){
  body{
      overflow-y: overlay;
      overflow-x: hidden;
  }

  ::-webkit-scrollbar{
      width: 12px;
  }

  ::-webkit-scrollbar-thumb{
      border-radius: 8px;
      border: solid 4px transparent;
      box-shadow: inset 0 0 10px 10px #87A7CE;
  }

  ::-webkit-scrollbar-thumb:hover{
      border: solid 2px transparent;
  }
}
